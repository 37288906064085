body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color:#151515; */
  background-color: #181818;
  /* background-color: white; */

}

code {
  font-family: 'Poppins', sans-serif,
    monospace;

}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');

.hero {
  background-color: black;
  position: relative;
  height: 100vh;
  font-family: 'Poppins', sans-serif;

}

.hero__title {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3 rem;
  z-index: 1;
}

.cube {
  position: absolute;
  top: 80vh;
  left: 45vw;
  width: 10px;
  height: 10px;
  border: solid 1px #003298;
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  -webkit-animation: cube 12s ease-in forwards infinite;
  animation: cube 12s ease-in forwards infinite;
}

.cube:nth-child(2n) {
  border-color: #0051f4;
}

.cube:nth-child(2) {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  left: 25vw;
  top: 40vh;
}

.cube:nth-child(3) {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  left: 75vw;
  top: 50vh;
}

.cube:nth-child(4) {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
  left: 90vw;
  top: 10vh;
}

.cube:nth-child(5) {
  -webkit-animation-delay: 8s;
  animation-delay: 8s;
  left: 10vw;
  top: 85vh;
}

.cube:nth-child(6) {
  -webkit-animation-delay: 10s;
  animation-delay: 10s;
  left: 50vw;
  top: 10vh;
}

@-webkit-keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }

  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}

@keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }

  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}

.Upperdiv {

  padding: 15px;
  text-align: center;
  margin-top: 10px;
  border-radius: 15px;



}

.mainhead1 {
  font-size: 5dvh;
  color: #72cdf7;
  font-family: 'Poppins', sans-serif
}

.middiv {

  margin-top: 25px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;


}

.descrption {
  font-size: 18px;
  text-align: justify;
  color: rgba(255, 255, 255, 0.725);
  font-family: 'Poppins', sans-serif,
}

.imgandec {
  padding: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid rgb(12, 12, 12);
  background-color: rgb(2, 2, 2);
  border-radius: 15px;
  margin-left: 0;
}

.middiv p {
  font-size: large;
  letter-spacing: 1px;
}

.leftdiv {
  margin-top: 20px;
  padding: 20px;
  margin-left: 5px;
  margin-right: 15px;
  border-radius: 15px;
  color: #C8E6C9;
  border-radius: 15px;
  /* border: 1px solid rgb(56, 54, 54); */
}

.leftdiv h1 {
  font-size: 9dvh;
  letter-spacing: 1px;
}


.rightdiv {
  border-radius: 15px;
  color: white;
  margin: 15px;
  display: flex;
  justify-content: center;
  align-items: center;


}

.rightdiv img {

  border-radius: 10px;
  /* box-shadow: rgba(243, 240, 240, 0.3) 0px 1px 2px 0px, rgba(243, 243, 244, 0.15) 0px 2px 6px 2px; */



}

.counterdiv {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 25px;

}

.mideldivone {
  padding: 5px;
  margin-top: 0px;
  color: #B3E5FC;
  border-radius: 10px;
  border: 1px solid rgb(12, 12, 12);
  background-color: rgb(2, 2, 2);
  border: 1px solid rgb(25, 25, 25);
  box-shadow: rgba(43, 43, 25, 0.25) 0px 0.0625em 0.0625em, rgba(43, 43, 42, 0.25) 0px 0.125em 0.5em, rgba(43, 43, 42, 0.1) 0px 0px 0px 1px inset;
  text-align: center;

  color: ;
}

.mideldivonea {
  padding: 5px;
  margin-top: 15px;
  color: #B3E5FC;
  border-radius: 10px;
  border: 1px solid rgb(12, 12, 12);
  background-color: rgb(2, 2, 2);
  /* border: 1px solid rgb(25, 25, 25); */
  box-shadow: rgba(43, 43, 25, 0.25) 0px 0.0625em 0.0625em, rgba(43, 43, 42, 0.25) 0px 0.125em 0.5em, rgba(43, 43, 42, 0.1) 0px 0px 0px 1px inset;
  text-align: center;
}

.mideldivonestg {
  margin-top: 15px;
  color: #B3E5FC;
  border-radius: 10px;
  border: 1px solid rgb(12, 12, 12);
  background-color: rgb(2, 2, 2);
  /* border: 1px solid rgb(25, 25, 25); */
  box-shadow: rgba(43, 43, 25, 0.25) 0px 0.0625em 0.0625em, rgba(43, 43, 42, 0.25) 0px 0.125em 0.5em, rgba(43, 43, 42, 0.1) 0px 0px 0px 1px inset;
  text-align: center;
}



.mideldivtow img {
  border-radius: 10px;
}

.mideldivtow {
  margin-top: 15px;
  padding: 15px;
  color: #B3E5FC;
  border-radius: 5px;
  border: 3px solid rgb(56, 54, 54);
}

.midelinformation {

  padding: 20px;
  margin: 15px;


}

.Cardone {
  border: 1px solid rgb(56, 54, 54);
  margin-top: 15px;
  padding: 15px;
  color: #E1F5FE;
  text-align: left;
}

.Cardtow {
  /* border: 1px solid rgb(56, 54, 54); */
  margin-top: 15px;
  padding: 15px;
  color: #E1F5FE;
  text-align: left;
}

.Cardthree {
  border: 1px solid rgb(12, 12, 12);
  margin-top: 15px;
  padding: 20px;
  color: #E1F5FE;
  text-align: left;
  background-color: rgb(2, 2, 2);
  border-radius: 15px;
}

.Cardfour {
  border: 1px solid rgb(12, 12, 12);
  margin-top: 15px;
  padding: 15px;
  color: #E1F5FE;
  text-align: left;
  background-color: rgb(2, 2, 2);
  border-radius: 15px;
}


.contentStyle {
  margin-left: 5%;
  margin-right: 5%;
}

.Carouseldiv {
  height: 650px;
  width: 100%;

}

.Carouseldiv img {}


.infodivmid {

  padding: 20px;
  margin-left: 15px;
}



.loginform {

  background-color: rgba(15, 1, 1, 0.01);
  margin-top: 100px;
  border-radius: 15px;
  color: white;
  text-align: center;

  margin: 25px;
  padding: 15px;

}

.loginright {
  padding: 30px;
  color: white;
  text-align: left;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  background-color: rgb(46 44 44 / 50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}


.oginleft {
  border: 1px solid red;
}

.loginright Form label {
  color: white;
}

.loginright img {
  width: 100%;
  height: auto;
}

.loginleft {
  padding: 15px;
  margin: 15px;
  text-align: center;

}

.navlinkdiv {
  margin: 25px
}

.alertmassage {
  border: 1px solid rgb(18, 17, 17);
  border-radius: 5px;
  text-align: center;
  padding: 15px;
  color: rgb(24, 24, 24);
}

.radio-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

}

.radio-item [type="radio"] {
  display: none;
}

.radio-item+.radio-item {
  margin-top: 15px;
}

.radio-item label {
  display: block;
  padding: 20px 60px;
  background: whitesmoke;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  min-width: 250px;
  white-space: nowrap;
  position: relative;
}

.radio-item label:after,
.radio-item label:before {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.radio-item label:after {
  height: 20px;
  width: 20px;
  border: 2px solid #524eee;
  left: 20px;
  top: calc(50% - 12px);
}

.radio-item label:before {
  background: #524eee;
  height: 10px;
  width: 10px;
  left: 27px;
  top: calc(50% - 5px);
  transform: scale(5);
  transition: .4s ease-in-out 0s;
  opacity: 0;
  visibility: hidden;
}

.radio-item [type="radio"]:checked~label {
  box-shadow: 0px 0px 5px 5px #cfcdcd;
  border: 1px solid rgb(158, 9, 9);
}


.radio-item [type="radio"]:checked~label:before {
  opacity: 1;
  visibility: visible;
  transform: scale(3);
}

input:checked+.inputdiv {
  border: 1px solid blueviolet;
  border-radius: 5px;
  text-align: center;
  color: blueviolet;
  background-color: rgb(18, 18, 18);

}

.alertmassage input {
  position: absolute;
  height: 100%;
  width: 100%;
  margin: -125px;
  margin-top: 0px;
  opacity: 0;

}

.membershipplan {
  border: 1px solid rgb(27, 26, 26);
  margin: 15px;
  padding: 15px;
  color: rgb(32, 31, 31);
  border-radius: 15px;


}

.btninvestnow {
  border: 1px solid red;
}

.custinfo {
  border: 1px solid rgb(24, 23, 23);
  padding: 15px;
  margin: 15px;
  ;
}

.custinfo label {
  color: black;
}

.Dashbord {
  margin: 2%;
  padding: 15px;
  border: 1px solid rgb(49, 48, 48);
  border-radius: 15px;
}

.ant-form-item-label label {
  color: white !important;
}

.custmerinfo {
  border: 1px solid rgb(70, 63, 63);
  margin: 2%;
  padding: 15px;
  text-align: left;
  border-radius: 15px;
}

.custmerinfo h1 {
  color: rgb(10, 10, 10);
}


.custmerinfo-left {
  border: 3px solid rgb(0, 0, 0);
  padding: 15px;
  border-radius: 15px;
  background-color: #030303;  
}

.custmerinfo-right {

  padding: 20px;
  border-radius: 15px;
  margin: 5%;


}

.payment {
  border: 1px solid rgb(61, 59, 59);
  margin: 1%;
  padding: 10px;
}

.payment h1 {
  color: rgb(61, 59, 59);
}

.Dashbord h1 {
  color: white;
}

.dashbordrowone {
  color: white;

  text-align: center;
  padding: 15px;
  margin: 15px;
  border-radius: 10px;
  background-color: black;

  ;
}

.dashbordrowtow {
  color: white;
  text-align: center;
  padding: 15px;
  margin: 15px;
  border-radius: 10px;
  background-color: black;

}

.dashborddiv {
  color: white;
  text-align: center;
  padding: 15px;
  margin: 15px;
  border-radius: 5px;
  background-color: #080808;
  border: 3px solid #101010;
}

.ant-statistic-title {
  color: white !important;
  text-align: left;

}

.ant-statistic-content-value-int {
  color: white !important;
  text-align: left;

}

label.ant-form-item-required::before {
  display: none;
}

.countdown-text-mobile {
  margin-left: 50%;
  border: 1px solid b;
  padding: 15px;
  color: #7b11e5;
}


.ant-radio-button .ant-radio-button-checked {
  background-color: #003298;
}

.ant-modal-content {
  background-color: rgb(7, 6, 6) !important;
}

.ant-modal-title {
  background-color: rgb(7, 6, 6) !important;
  color: white !important;
  ;
}

.inviescards {
  border: 1px solid rgb(42, 47, 31);
  margin: 15px;
  width: 200px;
  height: 200px;
  text-align: center;
  color: rgba(133, 98, 237, 0.8);
  margin: 15px;
  font-size: larger;
  border-radius: 15px;
  font-family:'Poppins', sans-serif ;
  background-color: #050505;
}

.responsive-image {
  max-width: 100%;
  height: auto;
}

.footer-links {
  font-size: 1.4rem;
}

.countdown-text-email {
  color: white;
}

.useredit {
  border: 1px solid rgb(93, 90, 90);
  margin: 15px;
  padding: 15px;
  color: white;
  border-radius: 15px;
}

ul {
  list-style: none;
}


.footer {

  color: white;
  background-color: #24262b;
  padding: 10px 0;

}

.footer ul li {
  list-style: none;

}

footer.h2 {
  text-align: center;
 
  margin-top: 0;
}


.footer-col {
  width: 50%;
  padding: 15px;
  margin-left: 15%;
  margin-right: 15%;

}

.footer-col h4 {
  font-size: 18px;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
  text-align: center;
}

.footer-col h4::after {
  content: '';
  position: absolute;
  left: 50px;
  bottom: -10px;
  background-color: #f00d58;
  height: 2px;
  box-sizing: border-box;
  width: 75px;
}

.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
}

.footer-col ul li a {
  font-size: 16px;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  color: #bbbbbb;
  display: block;
  transition: all 0.3s ease;
}

.footer-col ul li a:hover {
  color: #ffffff;
  padding-left: 8px;

}

.footer-col .social-links a {
  display: inline-block;
  height: 50px;
  width: 65px;
  background-color: rgba(255, 255, 255, 0.4);
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 25%;
  color: #ffffff;
  transition: all 0.5s ease;
}

.footer-col .social-links a:hover {
  color: #24262b;
  background-color: #ffffff;
}

/*responsive*/
/* @media(max-width: 767px){
  .footer-col{
    width: 50%;
    margin-bottom: 30px;
}
}
@media(max-width: 574px){
  .footer-col{
    width: 100%;
}
} */

.cradites {

  padding: 15px;
  text-align: center;
  margin: 15px;

}

.footercomapanyname {
  text-align: center;

  color: rgb(250, 210, 223);

}

.Forgotpassword {

  padding-right: 3px;
  padding-left: 3px;
  margin: 15px;

}

.Forgotpasswordd {
  border: 1px solid rgb(58, 0, 0);
  padding-right: 25px;
  background-color: rgb(14, 9, 9);
  border-radius: 15px;
  margin: 25px;


}

.frogtdivhead {

  text-align: center;
  color: lightgreen;
  margin: 15px;
  padding: 5px;

}


.invoiceupate {
  padding: 15px;
  margin: 15px;
  border: 1px solid rgb(15, 14, 14);
  background-color: rgb(5, 4, 4);
  border-radius: 15px;

}

.ant-progress-text {
  color: white !important
}

.subbtn {
  border: 2px solid rgb(60, 66, 60);
  background-color: rgb(160, 243, 161);
  border-radius: 15px;
  color: #ddbc29;


}

.midelinfotext {
  color: #7d7a79;
  padding: 25px
}


/* DatePickerCustom.css */
.ant-picker-clear {
  display: none;
}


/* CustomMenu.css */
.ant-menu-horizontal {
  background-color: black;

  /* Replace with your desired color */
  color: white;
  letter-spacing: 1px;
  font-size: 15px;

  padding: 15px;
  margin: 20px;


}

.mainmenu {

  margin: 15px;
}

.Userprofilelogo {

  margin: 20px;
}

.pldiv {
  padding: 8px;
  color: black;
  border-radius: 5px;
  font-weight: bold;

}

@media(max-width: 574px) {
  .inviescards {
    width: 100%;
  }
}


label {
  font-size: 18px;
  /* Change the value as desired */
}

/* YourForm.css */
@media (max-width: 576px) {

  /* Small screens (up to 576px) */
  .ant-form-item-label {
    font-size: 16px;
  }
}

@media (min-width: 576px) and (max-width: 992px) {

  /* Medium screens (576px to 992px) */
  .ant-form-item-label {
    font-size: 18px;
  }
}

@media (min-width: 992px) {

  /* Large screens (992px and above) */
  .ant-form-item-label {
    font-size: 20px;
  }
}

.profitserch {
  margin: 15px;
  padding: 15px;
  border: 1px solid rgb(15, 14, 14);
  background-color: rgb(5, 4, 4);
  border-radius: 15px;
}


.my-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid gray;

}

.my-table th,
.my-table td {

  padding: 8px;
  border: 1px solid black;

}

.my-table th {
  background-color: #363535;
  text-align: center;


}



.my-table tbody tr:hover {
  background-color: #e61313;
}

.my-table td {
  vertical-align: middle;
  text-align: center;
  font-weight: bold;
  background-color: white;


}

.Symbol-column {
  color: rgb(78, 78, 196);

}

.tradingviewchrt {

  padding: 20px;
  margin-top: 20px;
  margin-left: 0px;
}

.custom-pagination .ant-pagination {
  margin: 16px 0;
  /* Adjust the margin as needed */
}

.custom-pagination .ant-pagination-item-active a {

  border-color: #1890ff;
  /*Change the border color of the active page*/
  color: #fff;
  /*Change the text color of the active page*/
}

.custom-pagination .ant-pagination-item a:hover {
  color: #1890ff;
  /*Change the text color on hover*/
}

.custom-pagination .ant-pagination-item a {
  color: #1890ff;
  /*Change the text color on hover*/
}




.custom-pagination .ant-pagination-prev,
.custom-pagination .ant-pagination-next {
  border-color: #1890ff;
  /*Change the border color of the previous and next buttons*/
}

.custom-pagination .ant-pagination-prev a,
.custom-pagination .ant-pagination-next a {
  color: #1890ff;
  /*Change the text color of the previous and next buttons*/
}

.custom-pagination .ant-pagination-item-ellipsis {
  color: #1890ff;
}

.custom-pagination .ant-pagination-prev a:hover,
.custom-pagination .ant-pagination-next a:hover {
  color: #40a9ff;
  /* Change the text color on hover for the previous and next buttons*/
}

.newsletdiv {
  margin: 5px;
  padding: 15px;
  text-align: left;

}

.newsrightdiv {
  text-align: left;

}

.newsimg {
  border-radius: 8px;
  overflow: hidden;
  width: 165px;
}

.newsheader:hover {
  color: #524eee;
  cursor: pointer;

}

.newsheader {
  color: #727276;
  font-size: 22px;
  font-family: '' Poppins', sans-serif';
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .newsheader {
    font-size: 20px;
    /* Adjusted font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .newsheader {
    font-size: 18px;
    /* Adjusted font size for even smaller screens */
  }
}

.newssource {
  color: #727276;
  font-size: 16px;
}

@media (max-width: 768px) {
  .newssource {
    font-size: 14px;
    /* Adjusted font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .newssource {
    font-size: 12px;
    /* Adjusted font size for even smaller screens */
  }
}

.newsarticle {
  color: #727276;
  font-size: medium;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

}

.newsdiv {
  border: 1px solid rgb(12, 12, 12);
  margin: 15px;
  padding: 20px;
  background-color: rgb(2, 2, 2);
  border-radius: 15px;
  margin-left: 0;

}

.sourcecls {
  color: #ff00ae;
}

.hedingnews {
  color: #727276;
  font-size: xx-large;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.ant-table-cell {
  background-color: black !important;
  color: white !important;
  text-align: right !important;
  font-family: 'Poppins', sans-serif!important;



}


.custom-pagination {
  border: none !important;
}

.tableheade {
  color: rgb(186, 72, 146);
  text-align: left;
}


.outer {
  position: relative;
  margin: 0 50px;
  background: #111;
}

.button {
  height: 70px;
  width: 220px;
  border-radius: 50px;
}

.outer button,
.outer span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}

.outer button {
  background: #111;
  color: #f2f2f2;
  outline: none;
  border: none;
  font-size: 15px;
  z-index: 9;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
}

.button button {
  height: 60px;
  width: 210px;
  border-radius: 50px;
}

.outer span {
  height: 100%;
  width: 100%;
  background: inherit;
}

.button span {
  border-radius: 50px;
}

.outer span:nth-child(1) {
  filter: blur(7px);
}

.outer span:nth-child(2) {
  filter: blur(14px);
}

.outer {
  animation: rotate 1.5s linear infinite;
  background: linear-gradient(#14ffe9, #ffeb3b, #ff00e0);
}

@keyframes rotate {
  0% {
    filter: hue-rotate(0deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

.center {

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

}

.heatmap {
  padding: 15px;

}


.copanyinformation {
  border: 1px solid rgb(20, 20, 20);
  color: white;
  padding: 15px;
  text-align: center;
  background: rgb(0, 0, 0);
  border-radius: 50%;
  width: 500px;
  height: 500px;
  box-shadow: rgba(27, 27, 27, 0.25) 0px 54px 55px, rgba(31, 31, 31, 0.12) 0px -12px 30px, rgba(42, 42, 42, 0.12) 0px 4px 6px, rgba(42, 42, 42, 0.17) 0px 12px 13px, rgba(38, 38, 38, 0.09) 0px -3px 5px;
}

.pagediv {
  margin-top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center
}

.copanyinformation h2 {
  font-size: 8vh;
}

.quats {
  padding: 15px;
  margin-top: 25%;
  font-size: 25px;

}

.dqt {
  font-size: 30px;
}

.companylogo {}

.Copanydecrption {

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center
}


.aboutuspage {
  margin: 5%;
  border-radius: 15px;


}

.aboutuspage h1 {
  font-size: 6vh;
  color: yellowgreen;
  letter-spacing: 1px;
}

.aboutustext {

  color: white;
  padding: 15px;
  text-align: center;
  background-color: rgb(4, 4, 4);
  border-radius: 15px;
  margin: 10px;

}

.aboutusimg {

  border-radius: 15px;
  padding: 25px;
  margin: 10px;
}

.aboutuspra {
  color: white;
  font-size: 3vh;
  justify-content: center;
}

.ouerservicesdiv {
  border: 1px solid rgb(44, 42, 42);
  padding: 15px;
  margin-top: 25px;
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 15px;
  background-color: #030303;
}

.ouerserviceshead {
  color: rgb(169, 86, 241);
  text-align: center;
  font-size: 3vh;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif
}

.servisesinfo {
  margin: 15px;
  border: 1px solid rgb(22, 22, 22)
}

.faqiamges {
  text-align: center;
  color: lawngreen;
  letter-spacing: 2px;
  font-size: 3vh;
  margin: 5px;
  padding: 15px;

}

.ant-collapse-header-text {
  color: lightgreen !important;
  font-size: 18px;
  border: none !important;

}

.ant-collapse-content-box {
  color: white !important;
  background-color: black !important;
  letter-spacing: 1px;

}

.ant-collapse-content-active {
  background-color: black !important;
}



.answer {
  font-size: 15px;
  font-family: 'Courier New', Courier, monospace;
}

.ant-collapse-header {
  border: none;
}

.Faqlist {}

.ant-collapse-item {
  border: none !important;
}

.ant-collapse-header {
  border: none !important;
}

.ant-collapse-content-active {
  border: none !important;
}

.ant-collapse-content-box {
  padding-left: 15px;
}

.pdftable .ant-table {
  background-color: white !important;
}

.profitreturn {

  border: 1px solid gray;
  padding: 15px;
  background-color: white;
  margin: 15px;
  overflow-x: auto;
}

.profitreturnhead {
  text-align: center;
  color: rgb(59, 58, 58);
}


.Investmentplan {
  background-color: #1c1b1b;
  border: 1px solid black;
  padding: 15px;
  border-radius: 15px;

}

.Editplan {
  background-color: #1c1b1b;
  border: 1px solid black;
  padding: 15px;
  border-radius: 15px;
}

.ant-modal-close-x {
  color: red;
}

.Editplanmodel{
  background-color: #1c1b1b;
  border: 1px solid black;
  padding: 15px;
  border-radius: 15px;
}

.deleteplanmodel{
  background-color: #1c1b1b;
  border: 1px solid black;
  padding: 15px;
  border-radius: 15px;

}

.investmentplaninfo {
  border: 1px solid rgb(71, 71, 71) ;
  margin: 15px;
  padding: 15px;
  color: rgb(220, 74, 193);
  border-radius:15px ;
  overflow-x: auto;

}

.investmentplaninfo h3 {
  font-size: 2vh;
}



.table {

  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: #fff;
  color: #212529;
  border-collapse: collapse;
 
  
 
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  border: 1px solid black;
}

.table thead th {
  vertical-align: bottom;
  border: 1px solid black;
}

.table tbody + tbody {
  border: 1px solid black;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}


@media (max-width: 767px) {
  /* Adjust styles for screens smaller than 768px width */
  .investmentplaninfo  {
    overflow-x: scroll;
  }
}

.invdiv { 
  border: 1px solid rgb(71, 71, 71) ;
  margin: 15px;
  padding: 15px;
  color: rgb(220, 74, 193);
  border-radius:15px ;
}

.Expertise h2 {
  color: white;
  font-size: xx-large;
}

.Expertise p {
  color: white;
  font-size:20px ;
}

.convertdata {

 background-color: black;
  color: white;
  font-size: 17;
   margin: 50px;
   border: 1px solid white;
   padding: 50px;
   width: 500px;
   border-radius: 15px;
   background-color:rgb(17, 17, 17);

}


